import { ReactNode, createContext, useContext } from 'react';
import {
  BenchmarkType,
  ClientType,
  GridStrategyType,
  JobType,
  emptyGridStrategyRow,
  PlanType,
  useGetBenchmarksQuery,
  useGetClientsQuery,
  useGetIncumbentsQuery,
  useGetPlansByClientShortCodeQuery,
  useLazyGetVehicleDataSourceIDQuery,
} from '../../../reducers';
import { useFormikContext, FormikErrors } from 'formik';
import { GetFirstFplFunction, useFirstFpl } from '../../../hooks';

interface HomeContextType {
  errors: FormikErrors<JobType>;
  getBenchmarkName: (benchmarkShortCode?: string, strategies?: GridStrategyType[]) => string|undefined;
  getClientName: (clientShortCode: string) => string|undefined;
  getFirstFpl: GetFirstFplFunction;
  getFplName: (strategies?: GridStrategyType[]) => string|undefined;
  getIncumbentName: (strategies?: GridStrategyType[]) => string|undefined;
  getPlanNames: (selectedPlans?: PlanType[]) =>  String[];
  populateMissingFieldsAsync: (strategy: GridStrategyType) => Promise<GridStrategyType>;
}

const emptyHomeContext: HomeContextType = {
  errors: {},
  populateMissingFieldsAsync: async () => emptyGridStrategyRow,
  getBenchmarkName: () => "",
  getClientName: () => "",
  getFirstFpl: () => ({}),
  getFplName: () => "",
  getIncumbentName: () => "",
  getPlanNames: () => [],
};

const HomeContext = createContext<HomeContextType>(emptyHomeContext);

const HomeProvider = ({ children }: { children: ReactNode }) => {
  const { errors, values } = useFormikContext<JobType>();

  const benchmarksResult = useGetBenchmarksQuery('');

  const plansResult = useGetPlansByClientShortCodeQuery(values.clientShortCode, {
    skip: !values.clientShortCode,
  });
  
  const clientsResult = useGetClientsQuery('');

  const plansShortCode = values.plans && values.plans.length > 0 ? values.plans.map((plan: PlanType) => plan.planShortCode).toString() : "-";

  const incumbentsResult = useGetIncumbentsQuery(
    {
      jobID: values.jobID,
      plansShortCode,
    },
    { skip: !(values.jobID && plansShortCode) },
  );

  const [getVehicleDataSourceIDQueryTrigger] = useLazyGetVehicleDataSourceIDQuery();
  const { getFirstFpl } = useFirstFpl();

  const populateMissingFieldsAsync = async (strategy: GridStrategyType): Promise<GridStrategyType> => {
    const vehicleDataSourceIDResult = await getVehicleDataSourceIDQueryTrigger(strategy);
    const { data: dataSourceID, isSuccess } = vehicleDataSourceIDResult;
    return {
      ...strategy,
      benchmarkShortCode:
        values.fkRequestedBmk ||
        (strategy.benchmarkShortCode === values.fkSecondaryBmk ? '' : strategy.benchmarkShortCode),
      benchmark2ShortCode:
        values.fkSecondaryBmk ||
        (strategy.benchmark2ShortCode === values.fkRequestedBmk ? '' : strategy.benchmark2ShortCode),
      benchmarkName: '',
      benchmark2Name: '',
      dataSourceID: isSuccess ? dataSourceID : '',
      isPerformanceOnly: Boolean(strategy.isPerformanceOnly || values.isPerformanceOnlyBook),
      jobID: values.jobID,
    };
  };
  
  const findBenchmarkName = (benchmarkShortCode: String): string|undefined => {
    const benchmark = benchmarksResult.data?.find(
      (benchmark: BenchmarkType) => benchmark.benchmarkShortCode === benchmarkShortCode,
    );
    return benchmark?.benchmarkName;
  }

  const getBenchmarkName = (benchmarkShortCode?: string, strategies?: GridStrategyType[]): string|undefined => {
    if (benchmarkShortCode) {
      return findBenchmarkName(benchmarkShortCode);
    }
    const firstStrategy = strategies?.filter(s => s.type === "FPL" && s.benchmarkShortCode).sort(s => s.id).find(s => s.benchmarkName);
    return firstStrategy?.benchmarkName;
  }

  const getClientName = (clientShortCode: string): string|undefined => {
    const selectedClient = clientsResult.data?.find(
      (client: ClientType) => client.clientShortCode === clientShortCode,
    );
    return selectedClient?.clientName;
  };

  const getFplName = (strategies?: GridStrategyType[]): string|undefined => {
    const { firstFpl } = getFirstFpl({strategies});
    return firstFpl?.fplName;
  }
  
  const getIncumbentName = (strategies?: GridStrategyType[]): string|undefined => {
    const incumbentStrategy = strategies?.find(
      (strategy: GridStrategyType) => strategy.type === "INCUMBENT"
    );

    const incumbent = incumbentsResult.data?.find(
      (incumbent: GridStrategyType) => incumbent.strategyShortCode === incumbentStrategy?.strategyShortCode
    );
    return incumbent?.strategyName;
  }

  const getPlanNames = (selectedPlans?: PlanType[]) => {
    const planNames: String[] = [];
    if (selectedPlans) {
      selectedPlans.forEach(plan => {
        const foundPlan = plansResult.data?.find((p) => p.planShortCode === plan.planShortCode);
        if (foundPlan) {
          planNames.push(foundPlan.planName);
        }
      });
    }
    return planNames;
  }

  return (
    <HomeContext.Provider
      value={{
        errors,
        getBenchmarkName,
        getClientName,
        getFirstFpl,
        getFplName,
        getIncumbentName,
        getPlanNames,
        populateMissingFieldsAsync,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

const useHomeContext = () => useContext(HomeContext);

export { HomeProvider, useHomeContext };